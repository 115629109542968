import { useContext } from "react";

import { ConfigContext } from "../pages/_app";

function useSetting(name: string) {
  const { config } = useContext(ConfigContext);

  return config?.settings?.[name] || config?.[name];
}

export default useSetting;
