import dynamic from "next/dynamic";

import ApplicationShell from "../components/ApplicationShell";
import NotificationsTimeline from "../components/NotificationsTimeline";

const NotificationsMap = dynamic(
  () => import("../components/NotificationsMap"),
  { ssr: false },
);

export default function AppIndex() {
  return (
    <ApplicationShell title="Dashboard">
      <div className="mb-8 space-y-16">
        <NotificationsTimeline />
        <NotificationsMap />
      </div>
    </ApplicationShell>
  );
}
