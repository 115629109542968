import { useEffect, useState } from "react";

export default function useElementOffset(ref) {
  const [offset, setOffset] = useState({ width: 0, left: 0 });

  useEffect(() => {
    const handleResize = () =>
      ref.current &&
      setOffset({
        width: ref.current.offsetWidth,
        left: ref.current.offsetLeft,
      });
    if (ref.current) {
      handleResize();
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [ref]);

  return offset;
}
