import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PencilSquareIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import Link from "next/link";
import React, { useEffect, useMemo, useRef, useState } from "react";
import useSWR from "swr";

import useElementOffset from "../hooks/useElementOffset";
import useLocalStorageState from "../hooks/useLocalStorageState";
import useSetting from "../hooks/useSetting";
import { Notification } from "../backend/moco";

type Unit = "hour" | "day" | "week";

const unitWidth = 40;

const inMs = { hour: 3600000, day: 86400000, week: 604800000 };

function toTime(input: string | number) {
  return new Date(input).getTime();
}

function getFirstVisibleFromTime({ publications }: Notification) {
  return Math.max(...(publications?.map((p) => toTime(p.visible_from)) || []));
}

function getTimeDiff(unit: Unit, start: number, end: number) {
  const s = new Date(start);
  const e = new Date(end);

  if (unit === "hour") {
    return Math.ceil((e.getTime() - s.getTime()) / (1000 * 3600 * 24));
  } else if (unit === "week") {
    return e.getFullYear() - s.getFullYear();
  }
  return e.getMonth() - s.getMonth() + 12 * (e.getFullYear() - s.getFullYear());
}

function getISODate(input?: string | number) {
  const date = input ? new Date(input) : new Date();
  return date.toISOString().substring(0, 10);
}

function getISODateTime(input?: string | number) {
  const date = input ? new Date(input) : new Date();
  return date.toISOString().substring(0, 16);
}

function getFirstLocaleDateString(dates = [], field) {
  const first = Math.min(...dates.map((d) => new Date(d[field]).getTime()));
  return Number.isFinite(first) && new Date(first).toLocaleDateString("de");
}

function getLastLocaleDateString(dates = [], field) {
  const last = Math.max(...dates.map((d) => new Date(d[field]).getTime()));
  return Number.isFinite(last) && new Date(last).toLocaleDateString("de");
}

function getCalendarWeek(date) {
  const j1 = new Date(date.getFullYear(), 0, 1);
  const time = date.getTime();
  return Math.ceil(((time - j1.getTime()) / 86400000 + j1.getDay() + 1) / 7);
}

function UnitButton({ children, isActive, onClick }) {
  return (
    <button
      className={`rounded px-3 py-2 text-sm text-slate-600 ${
        isActive ? "bg-white font-bold" : "font-medium hover:bg-slate-100"
      }`}
      onClick={onClick}
      type="button"
    >
      {children}
    </button>
  );
}

function ToolButton({ children, className = "", onClick }) {
  return (
    <button
      className={`flex rounded bg-slate-100 p-2 text-sm font-medium text-slate-600 hover:bg-white ${className}`}
      onClick={onClick}
      type="button"
    >
      {children}
    </button>
  );
}

function getUnitFloor(input: number) {
  return Math.floor(input / unitWidth) * unitWidth;
}

function getUnitCeil(input: number) {
  return Math.ceil(input / unitWidth) * unitWidth;
}

function Bar({ notification, fraction, row, setActiveN10n, start, width }) {
  const textRef = useRef(null);
  const [tipWidth, setTipWidth] = useState(0);
  const yRow = row * 36;
  const ty1 = 30 + yRow;
  const ty2 = 40 + yRow;
  let nMin = Infinity;
  let nMax = -Infinity;
  const atis = notification.affected_time_intervals.map((ati) => {
    const atiStart = getUnitFloor((toTime(ati.start) - start) / fraction);
    const atiEnd = getUnitCeil((toTime(ati.end) - start) / fraction);
    nMin = Math.min(atiStart, nMin);
    nMax = Math.max(atiEnd, nMax);
    return (
      <rect
        key={ati.id}
        y={10 + yRow}
        x={atiStart + 2}
        width={atiEnd - atiStart - 4}
        height="24"
        rx="4"
        className="animate-[appear_1s] fill-orange-300 transition-[x,y] duration-300"
        data-cy="notifications-timeline-bar-ati"
      />
    );
  });
  const publications = notification.publications.map((p) => {
    const pStart = getUnitFloor((toTime(p.visible_from) - start) / fraction);
    const pEnd = getUnitCeil((toTime(p.visible_until) - start) / fraction);
    nMin = Math.min(pStart, nMin);
    nMax = Math.max(pEnd, nMax);
    return (
      <rect
        key={p.id}
        y={8 + yRow}
        x={pStart}
        width={pEnd - pStart}
        height="28"
        rx="6"
        className="animate-[appear_1s] fill-blue transition-[x,y] duration-300"
        data-cy="notifications-timeline-bar-publication"
      />
    );
  });
  const xTip =
    Math.max(0, nMin) + (Math.min(width, nMax) - Math.max(0, nMin)) / 2;

  return (
    <g
      className="group hover:cursor-zoom-in"
      onMouseEnter={() => setTipWidth(textRef.current?.getBBox().width)}
      onClick={() => setActiveN10n(notification)}
      data-cy="notifications-timeline-bar"
      key={notification.id}
    >
      <g className="hidden opacity-0 transition-opacity duration-300 group-hover:block group-hover:opacity-100">
        <rect
          y={5 + yRow}
          x={nMin - 3}
          width={nMax - nMin + 6}
          height="34"
          rx="9"
          className="fill-blue-300"
        />
      </g>
      {publications}
      {atis}
      <g className="hidden opacity-0 transition-opacity duration-300 group-hover:block group-hover:opacity-100">
        <polygon
          points={`${xTip + 2} ${ty1}, ${xTip - 6} ${ty2}, ${xTip + 10} ${ty2}`}
          className="fill-slate-600"
        />
        <rect
          y={38 + yRow}
          x={xTip - 16}
          width={tipWidth + 16 || 0}
          height="22"
          rx="4"
          className="fill-slate-600"
        />
        <text
          y={54 + yRow}
          x={xTip - 8}
          ref={textRef}
          className="fill-white text-sm"
        >
          {notification.title}
        </text>
      </g>
    </g>
  );
}

function Scale({ start, end, fraction, unit, width }) {
  const sDate = new Date(start);
  const sYear = sDate.getFullYear();
  const sMonth = sDate.getMonth();
  const sDay = sDate.getDate();
  const sHour = Math.abs(sDate.getTimezoneOffset()) / 60;
  const sDiff = getTimeDiff(unit, start, end);

  return (
    <svg
      width="100%"
      viewBox={`0 0 ${width} 24`}
      data-cy="notifications-timeline-scale"
    >
      {[...new Array(sDiff + 1)].map((_, i) => {
        const iStartDate =
          unit === "hour"
            ? new Date(sYear, sMonth, sDay + i, 0)
            : unit === "day"
              ? new Date(sYear, sMonth + i, 1, sHour)
              : new Date(sYear + i, 0, 1, sHour);
        const iEndDate =
          unit === "hour"
            ? new Date(sYear, sMonth, sDay + i + 1, 0)
            : unit === "day"
              ? new Date(sYear, sMonth + i + 1, 1, sHour)
              : new Date(sYear + i + 1, 0, 1, sHour);
        const iStart = Math.max(0, (iStartDate.getTime() - start) / fraction);
        const iEnd = Math.min(width, (iEndDate.getTime() - start) / fraction);
        const iWidth = Math.max(0, iEnd - iStart - (i === sDiff ? 0 : 2));

        return (
          <React.Fragment key={i}>
            <rect
              x={iStart}
              y="0"
              height="24"
              width={iWidth}
              className="animate-[appear_2s] fill-slate-200 transition-[x,width] duration-300"
              rx="8"
            />
            <text
              key={iStart}
              y="16.5"
              x={10 + iStart}
              className="animate-[appear_2s] fill-slate-500 text-sm"
            >
              {unit === "hour" && (
                <>
                  {iWidth > 100 && (
                    <tspan className="font-bold">
                      {iStartDate.toLocaleDateString("de", {
                        day: "numeric",
                        month: "long",
                      })}
                    </tspan>
                  )}{" "}
                  {iWidth > 140 && (
                    <tspan>
                      {iStartDate.toLocaleDateString("de", { year: "numeric" })}
                    </tspan>
                  )}
                </>
              )}
              {unit === "day" && (
                <>
                  {iWidth > 80 && (
                    <tspan className="font-bold">
                      {iStartDate.toLocaleDateString("de", { month: "long" })}
                    </tspan>
                  )}{" "}
                  {iWidth > 120 && (
                    <tspan>
                      {iStartDate.toLocaleDateString("de", { year: "numeric" })}
                    </tspan>
                  )}
                </>
              )}
              {unit === "week" && (
                <>
                  {iWidth > 120 && <tspan>Kalenderwochen</tspan>}{" "}
                  {iWidth > 80 && (
                    <tspan className="font-bold">
                      {iStartDate.toLocaleDateString("de", { year: "numeric" })}
                    </tspan>
                  )}
                </>
              )}
            </text>
          </React.Fragment>
        );
      })}
    </svg>
  );
}

const stopWhite = { stopColor: "white", stopOpacity: 0.7 };
const stopTransparent = { stopColor: "white", stopOpacity: 0 };
const prefix = "moco3.notificationsTimeline.";

export default function NotificationsTimeline() {
  const slug = useSetting("slug");
  const { data } = useSWR<Notification[]>(
    `/notification/?sso_config=${slug || ""}`,
    null,
    {
      refreshInterval: 60000, // 1 minute
    },
  );

  const [activeN10n, setActiveN10n] = useState<Notification>(null);
  const dateInputRef = useRef(null);
  const containerRef = useRef(null);
  const containerOffset = useElementOffset(containerRef);
  const [unit, setUnit] = useLocalStorageState<Unit>(`${prefix}.unit`, "day");
  const unitCount = Math.round(containerOffset.width / unitWidth);
  const width = unitCount * unitWidth;
  const fraction = inMs[unit] / unitWidth;
  const now = useMemo(() => new Date().getTime(), []);
  const beforeNow = useMemo(() => toTime(getISODate()) - inMs[unit], [unit]);
  const [start, setStart] = useLocalStorageState(`${prefix}.start`, beforeNow);
  const end = useMemo(
    () => start + inMs[unit] * unitCount,
    [start, unit, unitCount],
  );

  const notifications = useMemo(() => {
    const overlap = (testStart: string, testEnd: string) => {
      const testStartTime = toTime(testStart);
      const testEndTime = toTime(testEnd);
      return testStartTime < end && testEnd && testEndTime > start;
    };
    return data
      ?.filter(
        (n) =>
          n.affected_time_intervals.some((a) => overlap(a.start, a.end)) ||
          n.publications.some((p) => overlap(p.visible_from, p.visible_until)),
      )
      .sort((a, b) => getFirstVisibleFromTime(a) - getFirstVisibleFromTime(b))
      .reverse();
  }, [data, start, end]);

  const height = Math.max(55 + (notifications?.length * 36 || 0), 320);

  // close active notification when slug changes
  useEffect(() => setActiveN10n(null), [slug]);

  return (
    <div ref={containerRef} className="relative">
      <h2 className="mb-3 text-3xl font-bold text-slate-500">Timeline</h2>
      <div className="absolute right-0 top-2 flex space-x-3 rounded-lg border border-slate-200 bg-white p-1 text-xs font-medium text-slate-600">
        <div className="flex">
          <div className="mr-1 h-4 w-4 rounded-md bg-orange-300" /> Dauer der
          Einschränkung
        </div>
        <div className="flex pr-1">
          <div className="mr-1 h-4 w-4 rounded-md bg-blue" /> Anzeige-Zeitraum
        </div>
      </div>
      <div className="flex justify-between rounded-t-lg bg-slate-200 p-2">
        <div className="flex space-x-2">
          <UnitButton
            isActive={unit === "hour"}
            onClick={() => setUnit("hour")}
          >
            Stunden
          </UnitButton>
          <UnitButton isActive={unit === "day"} onClick={() => setUnit("day")}>
            Tage
          </UnitButton>
          <UnitButton
            isActive={unit === "week"}
            onClick={() => setUnit("week")}
          >
            Wochen
          </UnitButton>
        </div>
        <div className="flex space-x-2">
          <ToolButton
            onClick={() =>
              setStart(start - inMs[unit] * Math.round(unitCount / 3))
            }
          >
            <ChevronLeftIcon className="w-5" />
          </ToolButton>
          <ToolButton
            onClick={() =>
              setStart(start + inMs[unit] * Math.round(unitCount / 3))
            }
          >
            <ChevronRightIcon className="w-5" />
          </ToolButton>
          <ToolButton className="px-3" onClick={() => setStart(beforeNow)}>
            {unit === "hour" ? "Jetzt" : "Heute"}
          </ToolButton>
          <ToolButton onClick={() => dateInputRef.current.showPicker()}>
            <CalendarIcon className="w-5" />
            <input
              className="h-0 w-0 border-none p-0"
              type={unit === "hour" ? "datetime-local" : "date"}
              ref={dateInputRef}
              value={
                unit === "hour"
                  ? getISODateTime(start + inMs[unit])
                  : getISODate(start + inMs[unit])
              }
              onChange={(e) =>
                setStart(
                  e.target.value
                    ? toTime(e.target.value) - inMs[unit]
                    : beforeNow,
                )
              }
            />
          </ToolButton>
        </div>
      </div>

      {activeN10n && (
        <>
          <div className="absolute bottom-[33px] right-[1px] top-[100px] w-[300px] animate-[appear_1s] rounded-br-lg bg-white p-4 text-slate-500">
            <ToolButton
              className="absolute right-4 hover:bg-slate-200"
              onClick={() => setActiveN10n(null)}
            >
              <XMarkIcon className="w-5" />
            </ToolButton>
            <h3 className="mt-5 text-xs font-medium uppercase text-blue">
              {activeN10n.category}
            </h3>
            <div className="mt-1 font-semibold">{activeN10n.title}</div>
            <dl className="mt-6 text-sm">
              <dt className="font-medium text-slate-400">Beginn</dt>
              <dd className="-mt-5 ml-16 font-semibold">
                {getFirstLocaleDateString(
                  activeN10n.affected_time_intervals,
                  "start",
                ) || "-"}
              </dd>
              <dt className="mt-2 font-medium text-slate-400">Ende</dt>
              <dd className="-mt-5 ml-16 font-semibold">
                {getLastLocaleDateString(
                  activeN10n.affected_time_intervals,
                  "end",
                ) || "-"}
              </dd>
            </dl>
            <dl className="mt-6 text-sm">
              <dt className="font-medium text-slate-400">Start</dt>
              <dd className="-mt-5 ml-16 truncate font-semibold">
                {activeN10n.start_stop?.name || "-"}
              </dd>
              <dt className="mt-2 font-medium text-slate-400">Ende</dt>
              <dd className="-mt-5 ml-16 truncate font-semibold">
                {activeN10n.end_stop?.name || "-"}
              </dd>
            </dl>
            <Link
              href={`/notification/${activeN10n.id}`}
              className="absolute bottom-4 left-4 right-4 rounded bg-slate-100 p-2 text-center text-sm font-bold text-blue hover:bg-slate-200"
            >
              <PencilSquareIcon className="-mt-1 mr-1 inline-block w-5" />{" "}
              Meldung bearbeiten
            </Link>
          </div>
          <div className="pointer-events-none absolute bottom-[33px] right-[300px] top-[100px] w-[30px] bg-gradient-to-l from-slate-500 opacity-20" />
        </>
      )}

      <svg
        width="100%"
        viewBox={`0 0 ${width} ${height}`}
        className="mb-2 rounded-b-lg border-x border-b border-slate-200"
      >
        <defs>
          <linearGradient id="left-fade-in" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={stopWhite} />
            <stop offset="100%" style={stopTransparent} />
          </linearGradient>
          <linearGradient id="right-fade-in" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={stopTransparent} />
            <stop offset="100%" style={stopWhite} />
          </linearGradient>
        </defs>
        {[...new Array(Math.ceil(width / (inMs[unit] / fraction)))]
          .map((_, i) => {
            const iStart = start + i * inMs[unit];
            const date = new Date(iStart);
            const key = date.getTime();
            const x = (i * inMs[unit]) / fraction;
            const isNow = now < iStart + inMs[unit] && now >= iStart;
            let isBlue = false;
            if (unit == "hour") {
              isBlue = date.getHours() > 22 || date.getHours() < 6;
            } else if (unit == "day") {
              isBlue = date.getDay() === 6 || date.getDay() === 0;
            } else if (unit == "week") {
              isBlue = i % 2 === 1;
            }
            const fill = isNow
              ? "fill-green-100 stroke-green-200"
              : isBlue
                ? "fill-blue-50 stroke-blue-100"
                : "fill-white stroke-slate-100";
            const textFill = isNow ? "fill-green-500" : "fill-slate-400";

            return {
              sort: isNow ? 2 : isBlue ? 1 : 0,
              fragment: (
                <React.Fragment key={key}>
                  <rect
                    x={x}
                    y="0"
                    height={height}
                    width={unitWidth}
                    className={`${fill} animate-[appear_1s] stroke-1 transition-all duration-300`}
                    data-cy="notifications-timeline-column"
                  />
                  <text
                    key={x}
                    y={height - 28}
                    x={x + unitWidth / 2}
                    className={`${textFill} animate-[appear_2s] text-xs`}
                    textAnchor="middle"
                  >
                    {unit === "hour" && (
                      <tspan className="font-bold" dy="1em">
                        {date
                          .toLocaleTimeString("de", { hour: "2-digit" })
                          .substring(0, 2)}
                      </tspan>
                    )}
                    {unit === "day" && (
                      <>
                        <tspan>
                          {date.toLocaleDateString("de", { weekday: "short" })}
                        </tspan>
                        <tspan
                          x={x + unitWidth / 2}
                          dy="1.3em"
                          className="font-bold"
                        >
                          {date.toLocaleDateString("de", { day: "numeric" })}
                        </tspan>
                      </>
                    )}
                    {unit === "week" && (
                      <tspan className="font-bold" dy="1em">
                        {getCalendarWeek(date)}
                      </tspan>
                    )}
                  </text>
                </React.Fragment>
              ),
            };
          })
          .sort((a, b) => a.sort - b.sort)
          .map((i) => i.fragment)}
        {notifications?.map((n, i) => (
          <Bar
            key={n.id}
            notification={n}
            fraction={fraction}
            row={notifications.length - 1 - i}
            start={start}
            setActiveN10n={setActiveN10n}
            width={width}
          />
        ))}
        <rect // left fade-in overlay (see linear gradient defs)
          x="0"
          y="0"
          height={height}
          width={unitWidth}
          fill="url(#left-fade-in)"
          className="pointer-events-none"
        />
        <rect // right fade-in overlay (see linear gradient defs)
          x={width - unitWidth}
          y="0"
          height={height}
          width={unitWidth}
          fill="url(#right-fade-in)"
          className="pointer-events-none"
        />
      </svg>
      <Scale
        start={start}
        end={end}
        fraction={fraction}
        unit={unit}
        width={width}
      />
    </div>
  );
}
